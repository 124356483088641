<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">{{edit != null ? "Aditar Categoria" : "Adicionar Categoria"}}</h4>

          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'categorias' })"
            >
              <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
              Listar Categorias
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <label class="w-100 cursor-pointer">
                  <div class="uploadimg w-100 border p-5 text-center">
                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                    <h6 class="text-muted">clique para enviar imagem</h6>
                  </div>
                  <input
                    type="file"
                    @change="onFileChange"
                    name="image[]"
                    multiple
                    class="d-none"
                  />
                </label>
              </div>
              <div class="col-12" v-if="imgs.length > 0 || form.image != null">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Imagem</th>
                        <th scope="col">Capa</th>
                        <th scope="col">Fundo</th>
                        <th scope="col" class="text-center">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="text-center align-middle"
                        v-for="(i, index) in imgs"
                        :key="index"
                      >
                        <td class="text-left align-middle">
                          <b-img
                            width="100"
                            :src="i.url"
                            rounded
                            alt=""
                          ></b-img>
                        </td>
                        <td class="align-middle">
                          <input
                            class="form-check-input position-static"
                            type="radio"
                            v-model="i.capa"
                            value="1"
                            @click="altCheck(index)"
                          />
                        </td>
                         <td class="align-middle">
                          <input
                            class="form-check-input position-static"
                            type="radio"
                            v-model="i.fundo"
                            value="1"
                            @click="altCheckFundo(index)"
                          />
                        </td>
                        <td class="align-middle">
                          <b-button
                            variant="danger"
                            @click.prevent="removerImagem(index)"
                          >
                            <i
                              class="bx bx-trash font-size-16 align-middle"
                            ></i>
                          </b-button>
                        </td>
                      </tr>
                      <tr
                        class="text-center align-middle"
                        v-for="(i, index) in form.image"
                        :key="index + i.id"
                      >
                        <td class="text-left align-middle">
                          <b-img
                            width="100"
                            :src="i.image300"
                            rounded
                            alt=""
                          ></b-img>
                        </td>
                        <td class="align-middle">
                          <input
                            class="form-check-input position-static"
                            type="radio"
                            v-model="i.capa"
                            value="1"
                            @click="altCheckEditar(index, i)"
                          />
                        </td>
                        <td class="align-middle">
                          <input
                            class="form-check-input position-static"
                            type="radio"
                            v-model="i.fundo"
                            value="1"
                            @click="altCheckEditarFundo(index, i)"
                          />
                        </td>
                        <td class="align-middle">
                          <b-button
                            variant="danger"
                            @click.prevent="removerImagemEditar(index, i)"
                          >
                            <i
                              class="bx bx-trash font-size-16 align-middle"
                            ></i>
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-base">
                <label>
                  <span class="mr-1 text-danger">*</span>
                  Nome
                </label>
                <b-form-group>
                  <b-form-input
                    placeholder="Nome da categoria"
                    for="text"
                    v-model="form.name"
                    :class="{ 'is-invalid': form && $v.form.name.$error }"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 mb-3 col-lg-6">
                <label>Categoria Principal</label>
                <multiselect
                  v-model="form.category_id"
                  :options="options_categorys"
                  class="helo"
                  label="name"
                  placeholder="Categoria Principal"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoading"
                  @search-change="searchCategory"
                ></multiselect>
              </div>
              <div class="col-md-12 mb-3 col-lg-6">
                <label>Status</label>
                <multiselect
                  v-model="form.status"
                  :options="options_status"
                  class="helo"
                  label="text"
                  placeholder="Status"
                  select-label="Selecionar"
                  deselect-label="Remover"
                ></multiselect>
              </div>
              <div class="col-12 mb-3">
                <b-form-group>
                  <label>Descrição</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.description"
                    placeholder="Descrição..."
                    rows="8"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <div class="col-12 text-right">
                <b-button variant="success" @click.prevent="salvarDados()">
                  <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                  Salvar
                </b-button>
                <b-button
                  variant="danger"
                  class="ml-2"
                  @click.prevent="$router.push({ name: 'categorias' })"
                >
                  <i class="bx bx-x font-size-16 align-middle mr-2"></i>
                  Cancelar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    edit: {
        type: Object,
        default: function () {
            return null
        }
    },
  },

  components: {
    Multiselect,
  },
  data() {
    return {
      form: {
        status: { value: 0, text: 'Ativa' },
        description: '',
        name: null,
        category_id: null,
        image: []
      },
      options_status: [
        { value: 0, text: 'Ativa' },
        { value: 1, text: 'Bloqueada' },
      ],
      options_categorys: [],
      imgs: [],
      filtro: {
        status: 0,
        limit: 10,
      },
      isLoading: false,
    }
  },
  validations: {
    form: {
      name: { required },
    },
  },
  mounted() {
    this.listCategory()
     if(this.edit != null){
            this.form = this.edit
            this.form.status = {value: this.edit.status, text: this.edit.status == 0 ? "Ativa" : "Bloqueada"}
            if(this.edit.category && this.edit.category.id > 0){
                this.form.category_id = this.edit.category
            }
        }
  },
  methods: {
    ...mapActions('category', ['ActionCategoryAll', 'ActionCategoryCreate', 'ActionCategoryUpdate', 'ActionCategoryUpdateImage', 'ActionCategoryDeleteImage']),
    salvarDados() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
          if(this.edit != null){
              this.submitCategoryUpdate()
          }else{
            this.submitCategory()
          }
      }
    },
    async submitCategory() {
      try {
        this.form.status = this.form.status.value
        let data = new FormData()
        if (this.imgs.length > 0) {
          this.imgs.forEach((element) => {
            data.append('image[]', element.file)
            data.append('capa[]', element.capa)
            data.append('fundo[]', element.fundo)
          })
        }
        data.append('name', this.form.name)
        data.append('description', this.form.description)
        data.append('status', this.form.status)
        if (this.form.category_id != null) {
          data.append('category_id', this.form.category_id.id)
        }
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })
        await this.ActionCategoryCreate(data)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'categorias' })
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    async submitCategoryUpdate() {
      try {
        this.form.status = this.form.status.value
        let data = new FormData()
        if (this.imgs.length > 0) {
          this.imgs.forEach((element) => {
            data.append('image[]', element.file)
            data.append('capa[]', element.capa)
            data.append('fundo[]', element.fundo)
          })
        }
        data.append('id', this.form.id)
        data.append('name', this.form.name)
        data.append('description', this.form.description)
        data.append('status', this.form.status)
        if (this.form.category_id != null) {
          data.append('category_id', this.form.category_id.id)
        }
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })
        var dados = {
            data: data,
            id: this.form.id
        }
        await this.ActionCategoryUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'categorias' })
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
    altCheck(i) {
      var t = []
      this.imgs.forEach((element) => {
        element.capa = 0
        t.push(element)
      })
      t[i].capa = 1

        if(this.form.image != null || this.form.image != null){
            var y = []
            this.form.image.forEach(element => {
                element.capa = 0
                y.push(element)
            });
            this.form.image = y
        }

      this.imgs = t
    },
    altCheckEditar(i) {
        var t = []
        this.imgs.forEach((element) => {
            element.capa = 0
            t.push(element)
        })
        this.imgs = t

        var y = []
        this.form.image.forEach(element => {
            element.capa = 0
            var fg = {
                id: element.id,
                data: element
            }
            this.ActionCategoryUpdateImage(fg)
            y.push(element)
        });
        this.form.image[i].capa = 1
        this.form.image = y
        var fgg = {
            id: this.form.image[i].id,
            data: this.form.image[i]
        }
        this.ActionCategoryUpdateImage(fgg)  
    },

    altCheckEditarFundo(i) {
        var t = []
        this.imgs.forEach((element) => {
            element.fundo = 0
            t.push(element)
        })
        this.imgs = t

        var y = []
        this.form.image.forEach(element => {
            element.fundo = 0
            var fg = {
                id: element.id,
                data: element
            }
            this.ActionCategoryUpdateImage(fg)
            y.push(element)
        });
        this.form.image[i].fundo = 1
        this.form.image = y
        var fgg = {
            id: this.form.image[i].id,
            data: this.form.image[i]
        }
        this.ActionCategoryUpdateImage(fgg)  
    },

    altCheckFundo(i) {
      var t = []
      this.imgs.forEach((element) => {
        element.fundo = 0
        t.push(element)
      })
      t[i].fundo = 1

        if(this.form.image != null || this.form.image != null){
            var y = []
            this.form.image.forEach(element => {
                element.fundo = 0
                y.push(element)
            });
            this.form.image = y
        }

      this.imgs = t
    },
    async listCategory() {
      try {
        this.isLoading = true
        await this.ActionCategoryAll(this.filtro).then((res) => {
            if(this.edit != null){
                res.data.data.list.splice(res.data.data.list.indexOf(this.edit), 1);
            }
            this.options_categorys = res.data.data.list
            this.isLoading = false
        })
      } catch (_) {
        this.options_categorys = []
      }
    },
    searchCategory(e) {
      this.filtro.search = e
      this.listCategory()
    },
    onFileChange(e) {
      e.target.files.forEach((element) => {
        var g = {
          file: element,
          url: URL.createObjectURL(element),
          capa: 0,
          fundo: 0,
        }
        this.imgs.push(g)
        if(this.form.image != null){
            var i = 0
            var f = 0
            this.form.image.forEach(element => {
                if(element.capa == 1){
                    i++
                }
                 if(element.fundo == 1){
                    f++
                }
            });
            if(i <= 0){
                this.imgs[0].capa = 1
            }
             if(f <= 0){
                this.imgs[0].fundo = 1
            }
        }else{
            this.imgs[0].capa = 1
            this.imgs[0].fundo = 1
        }
      })
    },
    removerImagem(e) {
      var capa = this.imgs[e].capa
      var fundo = this.imgs[e].fundo
        this.imgs.splice(e, 1)
      if(capa != 0 && this.imgs.length > 0){
        this.imgs[0].capa = 1
      }
       if(fundo != 0 && this.imgs.length > 0){
        this.imgs[0].fundo = 1
      }
    },
    removerImagemEditar(e, data) {
        this.ActionCategoryDeleteImage(data.id).then((_)=>{
        if(data.capa != 0){
            this.form.image[0].capa = 1
        }
        if(data.fundo != 0){
            this.form.image[0].fundo = 1
        }

        if(data.capa != 0 && data.fundo != 0){
            var fg = {
                id: this.form.image[0].id,
                data: this.form.image[0]
            }
            this.ActionCategoryUpdateImage(fg)
        }

        this.form.image.splice(e, 1)
      })
    },
  },
}
</script>
